<template>
    <div class="privacy">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    隐私政策条款
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class="yhdj services_block">
            北京医护到家健康管理集团有限公司（以下或称为“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。请在使用我们的服务前，仔细阅读并了解本隐私权政策，我们将按照本政策收集、处理及披露您的信息。<br><br>
            客服热线：400-800-6996<br><br>
            本隐私政策的目录如下：<br>
            1.个人信息的定义与范围<br>
            2.个人信息的收集<br>
            3.我们如何使用 Cookie及同类技术<br>
            4.个人信息的保存<br>
            5.个人信息的使用<br>
            6.个人信息的共享、转让、公开披露<br>
            7.用户权利<br>
            8.未成年人保护<br>
            9.变更<br><br>

            <strong>1.个人信息的定义与范围</strong><br><br>
            1.1个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识別自然人个人身份的各种信息，在本隐私政策中包括:姓名、昵称、手机号码、用户密码、身份信息、照片、面部识别特征、生理健康状况（病情描述）、性别、年龄、职业信息、征信信息、是否有犯罪记录、是否有不良行为记录、执业证信息、订单信息及交易状态、支付信息、提现记录、银行卡号及其绑定手机号、评价信息、日志信息、设备信息、IP地址、手机充值记录。<br><br>
            1.2个人敏感信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，在本隐私政策中包括:手机号码、用户密码、身份证或其他身份证明、面部识别特征、生理健康状况（病情描述）、职业信息、征信信息、执业证信息、支付信息、银行卡号。<br><br>
            1.3匿名化处理后的信息，即通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后不能被复原的信息，匿名化处理后的信息不属于个人信息<br><br>
            1.4其他无法识别特定自然人身份或者反映特定自然人活动情况的信息不属于个人信息。<br><br>

            <strong>2.个人信息的收集</strong><br><br>
            :
            2.1您使用医护到家平台服务前，需要在医护到家平台创建账号并完善个人资料。通过用户主动提供的方式，我们会收集您的以下信息:<br><br>
            2.1.1您需要向我们提供您的手机号码，并设置您在医护到家平台的用户密码。如果您拒绝提供手机号码，医护到家平台将无法为您创建账号并提供服务。<br>
            2.1.2在您使用医护到家预约服务时，为了提升平台信息撮合的成功率，使提供服务的专业人员对您有更全面的了解，我们将需要您提供被服务人的姓名、性别、身份证号、国籍等信息，如并非您本人接受服务，还需要您提供您与被服务人之间的关系。<br>
            2.1.3如果您是提供服务用户，必须按照我们的提示向我们提供下列信息的全部或部分：姓名、身份证或其他身份证明、面部识别特征、执业证信息、银行卡信息，我们还需要查询您的信用信息、是否有犯罪、是否有不良行为记录或其他不适宜作为提供服务用户的情形。我们收集上述信息，是基于法律法规要求以及保护平台用户人身财产安全之需要。如果您拒绝提供上述信息，将无法作为医护到家平台的提供服务用户向其他用户提供服务。<br><br>
            2.2您在医护到家平台进行交易支付时，如果您使用第三方支付功能，平台除获取您的支付工具及订单支付状态外，不会获取其他个人信息。<br><br>
            2.3您可以匿名在医护到家平台对其他用户进行星级和文字评价，在此过程中请勿留存个人信息。<br><br>
            2.4当您与平台客服团队联系时，我们会以通话录音及人工录入的方式记录您的通话信息、您提出的意见或建议、您提供的姓名、联系方式，以便我们处理纠纷、解决问题或向您反馈结果。如果您拒绝我们收集此类信息，会导致您无法拨打我们的客服电话或客服团队无法将处理结果反馈到您，但不影响您使用医护到家平台核心业务功能。<br><br>
            2.5为开展数据分析和更好改善医护到家平台服务我们会收集您的日志信息，包括检索内容、IP地址、设备信息(包括设备型号、设备识别码、操作系统、系统语言、分辨率、电信运营商、SIM卡归属地)，作出特征模型并进行用户画像，以为您提供更加个性化、便捷的服务。我们会使用浏览器网络存储机制和用程序数据缓存，在您的设备上收集信息并进行本地存储。<br><br>
            2.6您使用医护到家平台以上各项功能时，我们还会收集您的以下信息:<br><br>
            2.6.1订单信息及交易状态。我们将记录您的订单信息及交易状态。我们收集上述信息是基于法律法规要求以及保护您的人身财产安全、依照平台规则处理用户纠纷之需要。<br><br>
            2.7您通过医护到家平台使用第三方服务时直接向第三方提供的个人信息，或授权平台共享给第三方的个人信息，将适用该第三方的隐私保护政策。当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的 Cookie或像素标签，这些 Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施。请您在使用前仔细阅读他们的隐私保护政策，以判断是否继续使用该第三方服务。<br><br>
            2.8请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明且收集了您的信息，我们会通过页面提示、交互流程、网站公告、另行签署协议等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。<br><br>

            <strong>3.我们如何使用 Cookie及同类技术</strong><br><br>
            3.1 Cookie是支持服务器端(或者脚本)在客户端上存储和检索信息的一种机制。当您使用平台服务时，为使您获得更轻松安全的访问体验，我们可能会使用Cookie或同类技术来收集和存储信息，在此过程中可能会向您的设备发送一个或多个Cookie或匿名标识符。这么做是为了收取您的信息用于了解您的偏好，进行咨询或数据分析，改善产品服务及用户体验，或及时发现并防范安全风险，为您提供更好的服务。我们不会将 Cookie用于本政策所述目的之外的任何用途，您可根据自己的偏好留存或刪除 Cookie。您可清除软件内保存的所有 Cookie，当您手动清除后您的相关信息即已删除。<br><br>
            3.2我们平台上还可能包含一些电子图像(以下简称"单像素GIF文件"或"网络 Beacon")，使用网络Beacon可以计算浏览网页的用户或访问某些 Cookie，我们会通过网络 Beacon收集您浏览网页活动的信息，例如: Internet协议(P)地址，浏览器类型， Internet服务提供商(ISP)，参考/退出页面，操作系统，日期/时间戳，和点击数据流等。<br><br>

            <strong>4.个人信息的保存</strong><br><br>
            4.1信息保存期限<br><br>
            4.1.1在用户使用医护到家平台服务期间，我们会持续保存用户的个人信息。<br>
            4.1.2当您自主删除个人信息或注销账户，我们将按照法律、法规规定的最短期限保留您的现有个人信息，在法律法规要求的最短保存期限内，我们将不再对您的个人信息进行商业化使用。当您的个人信息超出上述保存期限，我们将会对其进行匿名化处理。<br><br>
            a.为配合人民检察院、公安机关、国家安全机关侦查用户使用平台服务过程中产生的犯罪行为，更好保护其他用户生命财产安全，当用户自主删除个人信息或注销账户，我们将在刑法规定的犯罪追诉时效期间内，加密隔离存档用户个人信息。<br>
            b.如您使用医护到家平台服务的过程中，存在严重违反法律法规、平台协议、平台规则等情形，您的违法违约记录及相应的平台信用记录，将被永久保存。<br>
            c.平台与您另行签署的具体的隐私协议就某类信息的保存期限做特殊约定的，从其约定。<br><br>
            4.2信息存放地域<br><br>
            4.2.1我们收集的您的个人信息，将在中国内地存储和使用。<br>
            4.2.2如涉及向境外传输个人信息，我们将明确向您告知个人信息出境的目的、接收方、安全保障措施等情况，并另行征得您的同意。<br><br>
            4.3安全保护措施<br><br>
            4.3.1本公司将采用严格的安全制度以及行业通行的安全技术和程序来确保您的个人信息不丢失、泄露毁损或被未经授权的访问、使用。<br>
            4.3.2平台采取以下安全技术措施保护您的个人信息<br><br>
            a.用户个人信息被加密储存在服务器中，并通过数据隔离技术进行存储。<br>
            b.数据传输过程中使用加密传输协议。<br>
            c.严格控制数据访问权限，设立完善的敏感数据访问权限申请、审批制度。<br>
            d.建立数据安全监控和审计制度，进行全面数据安全控制。<br><br>
            4.3.3平台同时采取其他安全措施保护您的个人信息<br><br>
            a.公司内部颁布实施数据安全管理规范，明确对用户数据(包括用户个人信息)的保护标准和要求。<br>
            b.新项目、新系统上线前对数据安全(包括用户个人信息数据)进行项目风险评估。<br>
            c.与全体员工及服务外包人员签署保密协议，并严格按照工作职责分配数据访问权限。<br>
            d.定期开展面向公司全体员工及外包服务人员的信息安全教育及培训。<br><br>
            4.4安全事件处置<br><br>
            4.4.1为应对个人信息泄露、毁损、丢失等可能出现的安全风险，已经在公司范围内颁布多项制度，明确了安全事故、安全漏洞的分类分级标准，以及针对上述安全事故和漏洞，内部处理流程(包括应急响应流程)和补救措施。<br>
            4.4.2一旦发生安全事件，我们将及时向您告知事件基本情况和风险、我们已经采取或将要采取的措施、您如何自行降低风险的建议等。<br>
            4.4.3我们将及时以推送通知、信函或电话等方式将安全事件情况通知受影响的用户。当难以逐一告知用户时，我们将通过发布平台公告的方式发布警示信息。<br><br>
            4.5停止运营<br><br>
            4.5.1如医护到家平台停止运营，我们将至少提前30日在医护到家平台发布公告，并及时停止收集个人信息。<br>
            4.5.2停止运营后，我们将停止对个人信息的商业化使用，并在满足法律法规规定的最短保存期后，对收集的个人信息进行匿名化处理。<br><br>

            <strong>5.个人信息的使用</strong><br><br>
            5.1您的个人信息会被用于“个人信息的收集”条款明确列明的使用场景。<br>
            5.2您的个人信息可能被用于以下与"个人信息的收集"条款所声称的目的具有直接或合理关联的场景:<br><br>
            a.我们可能将来自医护到家平台某项服务的个人信息与来自其他项服务的信息结合起来，做出特征模型并进行用户画像，以为您提供更加个性化、便捷的服务。<br>
            b.我们可能以用户个人信息统计数据为基础设计、开发、推广全新的产品及服务。<br>
            c.我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息。<br>
            d.我们会向您发送信息和通知，包括但不限于为保证服务完成所必需的验证码、使用产品或服务时所必要的推送通知、关于医护到家平台服务的新闻、平台合作第三方的推广信息，或其他您可能感兴趣的内容。如您不希望继续接收上述信息，可以根据信息中提供的退订方式予以退订。<br><br>
            5.3凡是超出与“个人信息的收集”条款声称目的具有直接或合理关联的范围使用您的个人信息，我们会再次向您告知并征得您的明示同意。<br><br>
            5.4另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意:<br><br>
            a.与国家安全、国防安全直接相关的<br>
            b.与公共安全、公共卫生、重大公共利益直接相关的<br>
            c.与犯罪侦查、起诉、审判和判決执行等直接相关<br>
            e.所收集的个人信息是个人信息主体自行向社会公众公开的；<br>
            f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br>
            g.其他法律法规等规定的情形。<br><br>

            <strong>6.个人信息的共享、转让、公开披露</strong><br><br>

            6.1我们将遵守相关法律法规，对您的个人信息予以保密。除以下情况外，我们不会向其他人共享您的个人信息。<br><br>
            6.1.1只有共享您的个人信息，才能提供您所需要的服务。例如我们会收集您的手机号码、姓名、身份证或其他身份证明、病情描述等信息共享给提供服务的提供服务的专业人员，以便提供服务的专业人员为您提供服务。在共享之前，我们会通过弹窗获取您的明确同意。<br>
            6.1.2为了提升我们的产品及服务质量或向您提供全新的产品及服务，我们会在关联公司内部共享您的相关信息。<br>
            6.1.3您通过医护到家平台使用相关服务时，平台会按照“个人信息的收集”条款列明的共享内容与提供服务的专业服务人员共享您的个人信息。在平台向特定的第三方共享这些信息时，会征求您的同意。<br>
            6.1.4为维护用户合法权益，在协助处理与您有关的交易纠纷或争议时，我们可能向您的交易相对方或存在利害关系的第三方提供解决交易纠纷或争议所必需的信息。<br>
            6.1.5根据法律规定，刑事侦查机关为调查犯罪，依法定程序调取的必要个人信息，或行政机关、司法机构，依法定程序并经您授权同意调取的必要个人信息。<br>
            6.1.6在法律要求或允许的范围内，为了保护社会公共利益、财产或安全而提供必要的信息。<br>
            6.1.7另行获得您的明确同意或授权。<br><br>

            6.2非经法定程序或获得您的同意，我们不会将您的个人信息提供给任何第三方机构或个人。对我们与之共享个人信息的公司、组织和个人，我们会与之签署严格的保密协议，要求他们按照我们的说明、本隐私政策及其他任何相关的保密和安全措施来处理个人信息。<br><br>

            6.3我们将遵守相关法律法规，对您的个人信息予以保密。除非事先获得您的明确同意或授权，或依照法律规定所必须，我们不会公开披露您的个人信息。<br><br>

            6.4另外，根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意；<br><br>
            a.与国家安全、国防安全直接相关的；<br>
            b.与公共安全、公共卫生、重大公共利益直接相关的；<br>
            c.与犯罪侦查、起诉、审判和判決执行等直接相关的；<br>
            d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br>
            e.个人信息主体自行向社会公众公开的个人信息；<br>
            f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br><br>

            6.5如发生收购、兼并、重组等变更，我们会要求变更后的主体依然遵守本隐私政策约定，履行原有责任及义务。如变更后的主体需变更个人信息使用目的，我们会要求其事先获得您的明示同意。对内而言，在之前的收购、兼并活动中，对于被收购、被兼并对象管理的数据(包括用户数据)均采用与平台自有数据相同的标准和要求进行处理和保护。<br><br>

            <strong>7.用户权利</strong><br><br>
            7.1访问个人信息。您可以通过以下方式在医护到家平台访问个人信息:<br><br>
            a.账户，您可以通过医护到家平台对个人信息进行查询；<br>
            b.订单，您可以通过医护到家平台中的查询订单功能，访问您的订单信息及交易状态。<br><br>
            7.2更正个人信息。<br><br>
            当您需要更新您的个人信息，或发现医护到家平台收集、存储的个人信息有错误时，您有权做出更正或更新。您可以通过将您的更正申请提交给平台客服团队，或通过以下方式在医护到家平台自行更正或修改个人信息:<br><br>
            a.账户，您可以通过您在医护到家平台的账户更正或修改您提供的个人资料信息。<br>
            b.设置，您可以通过医护到家平台设置功能更正或修改密码等信息。<br><br>
            7.3删除个人信息。<br><br>
            如您希望删除个人信息，可以通过线上或电话联系平台客服，提交删除申请。在满足法律法规要求的最短保存期限要求的情况下，我们将对您的个人信息进行删除或匿名化处理。在上述法律法规要求的最短保存期限内，我们将不再对您的个人信息进行商业化使用。但当平台存在以下情形时，您删除个人信息的申请将不受法律法规要求的最短保存期限限制：<br><br>
            a.平台违反法律、行政法规的规定收集、使用您的个人信息。<br>
            b.平台违反我们双方的约定收集、使用您的个人信息。<br><br>
            7.4撤回同意。我们将通过以下方式保障用户撤回同意的权利:<br><br>
            a.医护到家平台发送的商业广告信息中，会向您说明具体退订方式，您可以按照信息中说明的退订方式撤回同意。<br>
            b.您可以通过设备的设置功能，关闭相应设备权限(包括位置、通讯录、照片、麦克风、相机、通知等)，撤回对平台获取您个人信息的授权。您还可以通过解除银行卡绑定、删除信息等方式撤回部分授权。当您撤回同意或授权后，可能导致我们无法为您继续提供撤回授权部分对应的服务。但您撤回同意或授权，不影响撤回前基于您的同意开展的个人信息处理。<br><br>
            7.5注销账号。您可以通过医护到家平台设置功能注销在医护到家平台的账号。除法律法规另有规定外，注销账号之后我们将停止为您提供服务，并根据本协议约定期限保存您的个人信息，保存期限届满后我们将对您的个人信息进行匿名化处理。<br><br>
            7.6如您无法通过上述方式访问、更正、删除个人信息，撤回同意或注销账号，您可以通过“客服”功能在线或者电话与平台的客服团队取得联系。平台客服团队可能需要验证您的身份，并在验证您的身份后20日内作出答复或合理解释。<br><br>

            <strong>8.未成年人保护</strong><br><br>
            8.1如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。<br><br>
            8.2对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。<br><br>
            8.3根据当地监管部门的相关规定，本平台仅对满足特定年龄要求的用户提供电子服务。<br><br>

            <strong>9.变更</strong><br><br>
            9.1如医护到家平台发生以下变化，我们将及时对本隐私政策进行相应的修订:<br>
            a.医护到家平台业务功能发生变更；<br>
            b.用户个人信息保存地域发生变更；<br>
            c.用户个人信息的使用规则发生变更；<br>
            d.我们的联络方式及投诉渠道发生变更；<br>
            e.发生其他可能影响用户个人信息安全或影响用户隐私权利的变更等。<br><br>
            9.2隐私政策修订后，我们会在医护到家平台发布最新版本，并以推送通知的方式告知用户，以便用户及时了解最新版本隐私政策。<br><br>
            9.3未经您的明确同意，我们不会削減您按照本隐私政策所享有的权利。<br><br>
            9.4如无特殊说明，修订后的隐私政策自公布之日起生效。
            <br><br>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'


export default {
  name: 'privacy',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()



    return {

        router
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .content{
        margin-top:20px;
    }
    .services_block{
        padding:30px;
        font-size: 20px;
        h3{
            font-size: 35px;
        }
    }

</style>
